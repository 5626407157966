/** @jsx jsx */
import { jsx } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import Section from "../components/Section"
import FormRegister from "../components/FormRegister"
import { ParallaxBanner } from "react-scroll-parallax"

const RegisterPage = () => {
  return (
    <Layout footerHalf={false}>
      <SEO
        title="Devenir partenaire"
        description="Simulassur s'occupe du reste ! Rejoignez nos 3 000 partenaires et développez votre chiffre d'affaires."
      />

      <ParallaxBanner
        layers={[
          {
            image: require("../images/vectors/bg-with-vectors.svg"),
            amount: 0.1,
          },
        ]}
        style={{ height: "auto" }}
      >
        <Section
          css={{
            marginTop: "50px",
          }}
        >
          <Container>
            <Row>
              <Col className="mb-4 mb-lg-0" lg={6}>
                <h1>
                  <span className="text-secondary">Concentrez-vous</span>
                  <br /> sur l'essentiel
                </h1>
                <p className="mt-4 mb-0">
                  Simulassur s'occupe du reste ! Rejoignez nos 3 000 partenaires
                  et développez votre chiffre d'affaires.
                </p>
              </Col>
              <Col lg={6}>
                <FormRegister />
              </Col>
            </Row>
          </Container>
        </Section>
      </ParallaxBanner>
    </Layout>
  )
}

export default RegisterPage
