import React from "react"
import axios from "axios"
import Alert from "react-bootstrap/Alert"
import { Col, Form } from "react-bootstrap"
import Button from "react-bootstrap/Button"
import { simulassurApi } from "../../utils/siteConfig"

class FormRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      status: null,
      firstName: "",
      lastName: "",
      address: "",
      zipCode: "",
      city: "",
      society: "",
      email: "",
      phone: "",
    }

    // this.handleServerResponse = this.handleInputChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.refAlert = React.createRef()
  }

  handleInputChange({ target }) {
    const value = target.type === "checkbox" ? target.checked : target.value
    this.setState({
      [target.name]: value,
    })
  }

  handleServerResponse = (ok, msg, form) => {
    this.setState({
      submitting: false,
      status: { ok, msg },
    })
    window.scrollTo(0, this.refAlert.current.offsetTop)
    if (ok) {
      form.reset()
    }
  }

  handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })
    axios
      .post(`${simulassurApi}/api/createContactCourtier`, this.state, {
        headers: {
          "Content-Type": "application/json",
          "X-AUTH-TOKEN": "Sim2019shGfuLKjBn",
        },
      })
      .then(r => {
        this.handleServerResponse(true, "Thanks for your messages!", form)
      })
      .catch(r => {
        this.handleServerResponse(false, "Une erreur est survenue", form)
      })
  }

  render() {
    return (
      <Form onSubmit={this.handleOnSubmit}>
        {this.state.status && (
          <Alert
            ref={this.refAlert}
            variant={!this.state.status.ok ? "danger" : "success"}
          >
            {this.state.status.msg}
          </Alert>
        )}
        <Form.Row>
          <Form.Group as={Col} controlId="formFirstName">
            <Form.Control
              type="text"
              placeholder="Prénom"
              name="firstName"
              value={this.state.firstName}
              onChange={this.handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="formLastName">
            <Form.Control
              type="text"
              placeholder="Nom"
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleInputChange}
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formAddress">
          <Form.Control
            type="text"
            placeholder="Adresse"
            name="address"
            value={this.state.address}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} xs={5} controlId="formZipCode">
            <Form.Control
              type="number"
              placeholder="Code postal"
              name="zipCode"
              value={this.state.zipCode}
              onChange={this.handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group as={Col} xs={7} controlId="formCity">
            <Form.Control
              type="text"
              placeholder="Ville"
              name="city"
              value={this.state.city}
              onChange={this.handleInputChange}
              required
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formSociety">
          <Form.Control
            type="text"
            placeholder="Société"
            name="society"
            value={this.state.society}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Control
            type="email"
            placeholder="Email"
            name="email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPhone">
          <Form.Control
            type="text"
            placeholder="Téléphone"
            name="phone"
            value={this.state.phone}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        <Button
          variant="secondary"
          className="btn-width"
          type="submit"
          disabled={this.state.submitting}
        >
          Devenir partenaire
        </Button>
      </Form>
    )
  }
}

export default FormRegister
